var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('span',[_vm._v("Status TensorFlow")]),_c('v-spacer'),_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" CPU("+_vm._s(_vm.status.cpus ? _vm.status.cpus : "")+") = "+_vm._s(_vm.status.cpuUsage ? _vm.status.cpuUsage.join(" ") : "")+" || RAM("+_vm._s(_vm.status.totalmem ? _vm.status.totalmem : "")+") = "+_vm._s(_vm.status.freemem ? ((_vm.status.freemem / _vm.status.totalmem) * 100).toFixed(2) + "%" : "")+" || UPT = "+_vm._s(_vm.status.uptimeObj ? ((_vm.status.uptimeObj.hours) + ":" + (_vm.status.uptimeObj.minutes)) : "")+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.getStartedCamera}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Atualizar ")])])],1),_c('v-card-text',[_c('v-simple-table',{attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ID")]),_c('th',{staticClass:"text-left"},[_vm._v("URL")]),_c('th',{staticClass:"text-left"},[_vm._v("Rodando")]),_c('th',{staticClass:"text-left"},[_vm._v("Imagem")]),_c('th',{staticClass:"text-left"},[_vm._v("Para em")]),_c('th',{staticClass:"text-left"},[_vm._v("Areas")]),_c('th',{staticClass:"text-left"},[_vm._v("Ações")])])]),_c('tbody',_vm._l((_vm.cameras),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.url))]),_c('td',[_vm._v(_vm._s(item.running ? "SIM" : "NÃO"))]),_c('td',[_c('v-img',{attrs:{"src":item.image,"contain":"","width":"150"},on:{"click":function($event){return _vm.openDialog(item.image)}}})],1),_c('td',[_vm._v(" "+_vm._s(item.end_time ? new Date(item.end_time).toLocaleString() : "Não Definido")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.areas.length)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.stopCamera(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}],null,true)},[_c('span',[_vm._v("Parar ")])])],1)])}),0)]},proxy:true}])})],1)],1),_c('v-dialog',{model:{value:(_vm.dialogImg),callback:function ($$v) {_vm.dialogImg=$$v},expression:"dialogImg"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Imagem")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.dialogImg = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar Janela")])])],1),_c('v-img',{attrs:{"src":_vm.img}}),_c('v-card-text',[_c('v-img')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }