<template>
  <div>
    <v-card class="mt-2">
      <v-card-title>
        <span>Status TensorFlow</span>
        <v-spacer></v-spacer>
        <v-chip color="success">
          CPU({{ status.cpus ? status.cpus : "" }}) =
          {{ status.cpuUsage ? status.cpuUsage.join(" ") : "" }} || RAM({{
            status.totalmem ? status.totalmem : ""
          }}) =
          {{
            status.freemem
              ? ((status.freemem / status.totalmem) * 100).toFixed(2) + "%"
              : ""
          }}
          || UPT =
          {{
            status.uptimeObj
              ? `${status.uptimeObj.hours}:${status.uptimeObj.minutes}`
              : ""
          }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="getStartedCamera">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>Atualizar </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <!-- TABELA -->
        <v-simple-table dark>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">URL</th>
                <th class="text-left">Rodando</th>
                <th class="text-left">Imagem</th>
                <th class="text-left">Para em</th>
                <th class="text-left">Areas</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in cameras" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.url }}</td>
                <td>{{ item.running ? "SIM" : "NÃO" }}</td>
                <td>
                  <v-img
                    :src="item.image"
                    contain
                    width="150"
                    @click="openDialog(item.image)"
                  ></v-img>
                </td>
                <td>
                  {{
                    item.end_time
                      ? new Date(item.end_time).toLocaleString()
                      : "Não Definido"
                  }}
                </td>
                <td>
                  {{ item.areas.length }}
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="stopCamera(item.id)"
                      >
                        <v-icon>mdi-stop</v-icon>
                      </v-btn>
                    </template>
                    <span>Parar </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <!-- dialog da imagem -->
    <v-dialog v-model="dialogImg">
      <v-card>
        <v-card-title>
          <span>Imagem</span>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="dialogImg = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Fechar Janela</span>
          </v-tooltip>
        </v-card-title>
        <v-img :src="img"></v-img>
        <v-card-text>
          <v-img></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TensorFlow",
  components: {},
  data: () => {
    return {
      urlTensor: "",
      cameras: [],
      status: {},
      img: "",
      dialogImg: false,
    };
  },
  methods: {
    getStartedCamera() {
      this.$http
        .get("tensorflow/proxy")
        .then((response) => {
          this.cameras = response.data;
          this.getStatusCamera();
        })
        .catch(() => {});
    },
    getStatusCamera() {
      this.$http
        .get("tensorflow/status")
        .then((response) => {
          this.status = response.data;
        })
        .catch(() => {});
    },
    stopCamera(id) {
      this.$http
        .delete("tensorflow/proxy/" + id)
        .then(() => {
          this.getStartedCamera();
        })
        .catch(() => {});
    },
    openDialog(img) {
      this.img = img;
      this.dialogImg = true;
    },
  },
  mounted() {
    this.getStartedCamera();
  },
};
</script>

<style>
</style>